import React from 'react';
import Obfuscate from 'react-obfuscate';
import SEO from '../../components/SEO';
import Layout from '../../layouts/index';

const Contact = props => (
  <Layout bodyClass="page-contact">
    <SEO title="Contact" />
    <div className="container">
      <div className="row">
        <header className="col-12  text-center">
          <h1>Get in touch</h1>
        </header>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-12 text-center">
          <p>
            
          <span className='gradient1'><i className="fa fa-envelope-open-o fa-1x" /></span>

            &nbsp;&nbsp;&nbsp;
            <Obfuscate email="hi@wxt.media" />
          </p>
          <p>
          <span className='gradient1'><i className="fa fa fa-volume-control-phone fa-1x" /></span>
            &nbsp;&nbsp;&nbsp;
            <Obfuscate tel="+49 176 23 19 58 88" />
          </p>

          <p>
            <a
              href="https://www.xing.com/profile/Ines_Opifanti"
              className="gradientIcon"
              target="_blank"
            >
             <span className='gradient2'><i className="fa fa-xing fa-3x" aria-hidden="true"></i></span>

            </a>
          </p>
        </div>
      </div>
    </div>
    <svg width="0" height="0">
      <radialGradient id="rg" r="150%" cx="30%" cy="107%">
        <stop stopColor="#FF9A76" offset="0" />

        <stop stopColor="#e52e71" offset="0.9" />
      </radialGradient>
    </svg>
    <svg width="0" height="0">
      <radialGradient id="rg2" r="150%" cx="30%" cy="107%">
        <stop stopColor="#43E695" offset="0" />

        <stop stopColor="#3BB2B8" offset="0.9" />
      </radialGradient>
    </svg>
  </Layout>
);

export default Contact;
